
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DivisionModel from "@/components/modals/forms/settings/DivisionModel.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { VueCookieNext } from "vue-cookie-next";

// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Division",
  components: {
    Form,
    Field,
    Datatable,
    DivisionModel,
  },
  data() {
    return {
      division: "" as any,
      divisions: [] as any,
      lists: [] as any,
      componentKey: 0,
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "120px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
        },
        {
          name: "Division Name(Bangla)",
          key: "division_name_bng",
          sortable: true,
        },
        {
          name: "Division Name(English)",
          key: "division_name_eng",
          sortable: true,
        },
        {
          name: "Division BBS Code",
          key: "bbs_code",
          sortable: true,
        },
      ],

      load: true,
      search: "",
      tableData: [],
      data: {},
      showdatatable: false,
    };
  },
  async created() {
    await this.geoDivision();

    this.emitter.on("divison-updated", async () => {
      await this.geoDivision();

      this.componentKey += 1;
    });
  },
  methods: {
    async geoDivision() {
      this.load = true;
      await ApiService.get("configurations/geo_division/list")
        .then((response) => {
          this.load = false;

          this.divisions = response.data.data;
          this.lists = response.data.data;
          this.showdatatable = true;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    searchItems() {
      if (this.search !== "") {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        // Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      data.componenyKey = this.componentKey;
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },

    view(divison) {
      this.data = divison;
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("configurations/geo_division/delete/" + `${id}`)
            .then((response) => {
              if (response.data.status == "error") {
                Swal.fire("Error!", response.data.message, "error");
              } else {
                Swal.fire("Deleted!", response.data.message, "success");
                this.emitter.emit("divison-updated", true);
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
